const teams = {
    Qatar: '01-Qatar.png',
    Ecuador: '02-Ecuador.png',
    England: '03-England.png',
    Iran: '04-Iran.png',
    Senegal: '05-Senegal.png',
    Netherlands: '06-Netherlands.png',
    'United States': '07-UnitedStates.png',
    Wales: '08-Wales.png',
    Argentina: '09-Argentina.png',
    SaudiArabia: '10-SaudiArabia.png',
    Denmark: '11-Denmark.png',
    Tunisian: '12-Tunisian.png',
    Mexico: '13-Mexico.png',
    Poland: '14-Poland.png',
    France: '15-France.png',
    Australia: '16-Australia.png',
    Morocco: '17-Morocco.png',
    Croatia: '18-Croatia.png',
    Germany: '19-Germany.png',
    Japan: '20-Japan.png',
    Spain: '21-Spain.png',
    'Costa Rica': '22-CostaRica.png',
    Belgium: '23-Belgium.png',
    Canada: '24-Canada.png',
    Switzerland: '25-Switzerland.png',
    Cameroon: '26-Cameroon.png',
    Uruguay: '27-Uruguay.png',
    'South Korea': '28-SouthKorea.png',
    Portugal: '29-Portugal.png',
    Ghana: '30-Ghana.png',
    Brazil: '31-Brazil.png',
    Serbia: '32-Serbia.png',
}

export const WORLDCUP_TEAMS = Object.keys(teams).map(name => ({
    name,
    url: `https://cdn.moonfit.xyz/image/original/world-cup/${teams[name]}`
}))
