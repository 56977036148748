import React from 'react'

const ButtonMintNFT = ({children, isLoading = false, isDisabled = false, handleMintNFT = () => {}}) => {
    const _renderIcon = () => {
        if (isLoading) {
            return (
                <svg role="status"
                     className="inline w-4 h-4 mr-3 text-white animate-spin"
                     viewBox="0 0 100 101" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"/>
                </svg>
            )
        }

        return (
            <svg className="inline w-6 h-6 mr-2"
                 width="22"
                 height="24" viewBox="0 0 22 24"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.9087 0H6.10555C5.08442 0 4.24161 0.686203 3.98047 1.60627H15.8961C17.8898 1.60627 19.5117 3.22823 19.5117 5.22188V20.904C20.4462 20.6402 21.118 19.7798 21.118 18.7781V2.20936C21.118 0.974719 20.1154 0 18.9087 0V0Z"
                    fill="white"/>
                <path
                    d="M13.6851 14.4205V9.57979L9.49295 7.1593L5.30078 9.57979V14.4205L9.49295 16.841L13.6851 14.4205ZM8.90791 15.0263L7.15047 12.39C6.99302 12.1538 6.99302 11.8461 7.15047 11.6099L8.90791 8.97397C9.17369 8.55993 9.81222 8.56002 10.078 8.97397L11.8354 11.6099C11.9928 11.8461 11.9928 12.1538 11.8354 12.39L10.078 15.0263C9.81222 15.4404 9.17364 15.4403 8.90791 15.0263Z"
                    fill="white"/>
                <path
                    d="M15.8953 3.01257H3.09217C1.85767 3.01257 0.882812 4.01523 0.882812 5.22193V21.7907C0.882812 23.0252 1.88547 24.0001 3.09217 24.0001H15.8953C17.13 24.0001 18.1047 22.9973 18.1047 21.7907V5.22193C18.1047 3.98729 17.102 3.01257 15.8953 3.01257ZM3.89533 9.17392C3.89533 8.92271 4.02934 8.69059 4.24689 8.56501L9.14219 5.73854C9.35978 5.61292 9.62776 5.61292 9.84536 5.73854L14.7407 8.56501C14.9582 8.69064 15.0922 8.92271 15.0922 9.17392V14.8265C15.0922 15.0777 14.9582 15.3098 14.7407 15.4354L9.84536 18.2619C9.62776 18.3875 9.35978 18.3875 9.14219 18.2619L4.24689 15.4354C4.02934 15.3098 3.89533 15.0777 3.89533 14.8265V9.17392ZM14.389 20.9875H4.59845C3.66653 20.9529 3.66723 19.6156 4.59845 19.5813H14.389C15.321 19.6159 15.3203 20.9533 14.389 20.9875Z"
                    fill="white"/>
                <path
                    d="M10.4068 12L9.49441 10.6316L8.58203 12L9.49441 13.3687L10.4068 12Z"
                    fill="white"/>
            </svg>
        )
    }

    return (
        <button type="button" disabled={isDisabled} className="button button-secondary flex items-center" onClick={handleMintNFT}>
            {_renderIcon()}
            {children}
        </button>
    )
}

export default ButtonMintNFT
