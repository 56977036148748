const baseUrl ="https://prod-cdn.moonfit.xyz/image/original/assets/astar-rewards/"

export const InfoIcon=`${baseUrl}Info-color-white_1.png`
export const AmountInfo=`${baseUrl}amount-info_1.png`
export const AstarBg1=`${baseUrl}astar-bg-1_1.png`
export const AstarBg2=`${baseUrl}astar-bg-2_1.png`
export const AstarBgMobile1=`${baseUrl}astar-bg-mobile-1_1.png`
export const AstarFooterBgMobile=`${baseUrl}astar-footer-bg-mobile_1.png`
export const AstarFooterBg=`${baseUrl}astar-footer-bg_1.png`
export const AstarFooterImg=`${baseUrl}astar-footer-img_1.png`
export const AstarFooterImgMobile=`${baseUrl}astar-footer-img-mobile_1.png`
export const AstarRewards=`${baseUrl}astar-reward_1.png`
export const CheckPurple= `${baseUrl}check-purple_1.png`
export const CloseBtn=`${baseUrl}close-border_1.png`
export const CloseNoBorder=`${baseUrl}close-no-border_1.png`
export const NotConnectBg=`${baseUrl}not-connect-bg_1.png`
export const RewardsImg =`${baseUrl}share-rewards-img_1.png`
export const Rewards=`${baseUrl}share-rewards_1.png`
export const BannerBg=`${baseUrl}stake-banner-bg_1.png`
export const BenefitBg=`${baseUrl}stake-benefit-bg_1.png`
export const BannerBgMobile=`${baseUrl}stake-info-bg-mobile_1.png`